import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { UserAuth } from './user';
import { environment } from '@env/environment';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { Router } from '@angular/router';
import { ApiService } from '@core/services/api.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { TabsSharedDataService } from '@modules/tabs/tabs-shared-data.service';

const routes = {
    funcionalidadesUsuarioCrud: `crud/usuario/funcionalidades`
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<UserAuth>;
    public currentUser: Observable<UserAuth>;
    public loggedIn = new BehaviorSubject<boolean>(false);

    constructor(private apiService: ApiService,
        private localStorageService: LocalStorageService,
        private router: Router,
        private permissionsService: NgxPermissionsService,
        private modalService: NgbModal,
        private tabService: TabsSharedDataService) {
        this.currentUserSubject = new BehaviorSubject<UserAuth>(JSON.parse(localStorageService.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.loggedIn = new BehaviorSubject<boolean>(localStorageService.getItem('token') != undefined);
    }

    public get isLoggedIn() {
        return this.loggedIn.asObservable().pipe(delay(0));
    }

    public get currentUserValue(): UserAuth {
        return this.currentUserSubject.value;
    }

    login(usuario: string, senha: string) {
        let dataInfo = { usuario, senha };
        return this.apiService.post<any>(environment.auth, dataInfo)
            .pipe(map(data => {
                if (data.success) {
                    let helper = new JwtHelperService();
                    let dados = helper.decodeToken(data.token)
                    let dadosUsuario = <UserAuth>dados.token;
                    this.localStorageService.setItem('currentUser', JSON.stringify(dadosUsuario));
                    this.localStorageService.setItem('token', JSON.stringify(data.token));
                    let dt = moment().add(environment.tempoAcesso, 'minutes').toDate();
                    this.localStorageService.setItem('dataExpiracaoAcesso', dt);
                    this.localStorageService.setItem('dataExpiracaoToken', dt);
                    this.currentUserSubject.next(dadosUsuario);
                    this.funcionalidadesUsuario().subscribe((funcionalidades) => {
                        dadosUsuario.funcionalidades = funcionalidades.map(x => x.idFuncionalidade.toString());
                        this.localStorageService.setItem('currentUser', JSON.stringify(dadosUsuario));
                        this.permissionsService.loadPermissions(dadosUsuario.funcionalidades);
                    })
                }
                return data;
            }));
    }

    logout(setReturnUrl?: boolean, message?: string) {
        let tour = document.querySelector('joyride-step')
        tour ? tour.classList.add('d-none') : null

        if (!this.router.url.startsWith('/login')) {
            this.localStorageService.removeItem('currentUser');
            this.localStorageService.removeItem('token');
            localStorage.removeItem('cnseg_storage')
            this.currentUserSubject.next(null);
            this.loggedIn.next(false);
            this.modalService.dismissAll(true);

            if (setReturnUrl)
                this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url, msg: message } });
            else
                this.router.navigate(['/login'], { queryParams: { msg: message } });
        }
    }


    funcionalidadesUsuario() {
        return this.apiService.getCustomApi<any>(`${environment.lote}/${routes.funcionalidadesUsuarioCrud}`);
    }

    refreshToken() {
        let params = new HttpParams();
        params = params.append('ignoreLoading', 'true');
        params = params.append('ignoreError', 'true');
        return this.apiService.getCustomApi<any>(`${environment.refreshToken}`, params);
    }
}