import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@core/auth/authentication.service';
import { NotificationService } from '@core/services/notification.service';
import { faArrowRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { UsuarioService } from '@core/services/usuario.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SobreComponent } from '@modules/sobre/sobre.component';
import { LoginAction } from '@core/action/action';
import { environment } from '@env/environment';
import { AlterarSenhaLogin } from '@core/models/usuario';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { HttpStatusEnum } from '@core/http-interceptors/http-status';
import { RecaptchaService } from '@core/services/recaptcha.service';
import { AuthService } from '@modules/consulta/produtos/rnpa-pessoas/services/auth.service';
import { DashboardService } from '@core/services/dashboard.service';
import { EStorage } from '@shared/utils/enums/storage.enum';
import moment from 'moment';
import { StorageService } from '@shared/utils/services/storage.service';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loginFormRnsPessoas: FormGroup;
    alterarSenhaForm: FormGroup;
    esqueciSenhaForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    faArrowRight = faArrowRight;
    faCheck = faCheck;
    faTimes = faTimes;
    prosseguirHover: boolean;
    message: string;
    isEsqueciSenha: boolean;
    isAlterarSenha: boolean;
    isLoginRnsPessoas: boolean;
    isLoginPadrao: boolean;
    isLogin: boolean;
    token: string;

    mostrarRecaptcha: boolean = false;
    tentativasLogin: number = 0;
    totalTentativasLogin: number = 4;
    recaptchaTokenValidation: string;
    recaptchaResultadoValidation: any;

    activeTabHeigth: boolean = false;
    confirmPasswordVisible: boolean = false;
    showPassword: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private notificationService: NotificationService,
        private usuarioService: UsuarioService,
        private modalService: NgbModal,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private recaptchaService: RecaptchaService,
        private _authService: AuthService,
        private dashboardService: DashboardService,
        private storageService: StorageService,
    ) {
        if (sessionStorage.getItem('recaptcha')) {
            if (Number(sessionStorage.getItem('recaptcha')) >= this.totalTentativasLogin) {
                this.mostrarRecaptcha = true;
                this.showRecaptcha();
            }
        } else {
            this.mostrarRecaptcha = false;
        }
    }

    ngOnInit() {
        this.modalService.dismissAll(true);
        this.loginForm = this.formBuilder.group({
            login: ['', Validators.compose([Validators.email, Validators.required])],
            password: ['', Validators.required]
        })

        this.loginFormRnsPessoas = this.formBuilder.group({
            password: ['', Validators.required],
            seguradora: [],
            subcodigo: [],
            usuario1: [],
            usuario2: []
        })

        this.alterarSenhaForm = this.formBuilder.group({
            login: ['', Validators.compose([Validators.email, Validators.required])],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        })

        this.esqueciSenhaForm = this.formBuilder.group({
            login: ['', Validators.compose([Validators.email, Validators.required])]
        })
        this.isLogin = true;
        this.isLoginPadrao = true;
        this.route.queryParams.subscribe((params) => {

            if (params.action == LoginAction.TrocarSenha && params.token) {
                this.token = params.token;
                this.router.navigate(
                    ['.'],
                    { relativeTo: this.route, queryParams: { token: null, action: null, returnUrl: null } }
                );
                this.usuarioService.validarHash(this.token).subscribe((data) => {
                    this.token = data.hash;
                    this.alterarSenhaForm.controls.login.setValue(data.email);
                    this.alterarSenhaForm.controls.login.disable();
                    this.isAlterarSenha = true;
                    this.isLogin = false;
                }, (error) => {
                    this.message = error.error.MSG;
                })
            }

            if (params.action == LoginAction.EsqueciMinhaSenha) {
                this.esqueciMinhaSenha();
                if (params.email)
                    this.esqueciSenhaForm.controls.login.setValue(params.email);
            }
            this.returnUrl = params.returnUrl;
            this.message = params.msg;
        })

        this.passwordLogin.valueChanges.subscribe((val) => {
            val ? this.showPassword = true : this.showPassword = false;
        })

        this.passwordLoginRnsPessoas.valueChanges.subscribe((val) => {
            val ? this.showPassword = true : this.showPassword = false;
        })
    }

    submitLogin(): void {
        if (this.loginForm.valid) {
            let login = this.loginForm.controls.login.value;
            let senha = this.loginForm.controls.password.value;
            this.recaptchaValidator()

            if (this.mostrarRecaptcha && this.recaptchaTokenValidation) {
                this.recaptchaService.getResult(this.recaptchaTokenValidation).subscribe(resultado => {
                    resultado.success ? this.enviarLogin(login, senha) : this.notificationService.info('Entre em contato com o suporte!!!')
                });
            }
            else {
                this.enviarLogin(login, senha)
            }
        }
    }

    submitLoginRnsPessoas(): void {
        if (this.loginFormRnsPessoas.valid) {
            let login = this.montarLoginRNSPessoas();
            let senha = this.loginFormRnsPessoas.controls.password.value;
            this.recaptchaValidator()

            if (this.mostrarRecaptcha && this.recaptchaTokenValidation) {
                this.recaptchaService.getResult(this.recaptchaTokenValidation).subscribe(resultado => {
                    resultado.success ? this.enviarLogin(login, senha) : this.notificationService.info('Entre em contato com o suporte!!!')
                });
            }
            else {
                this.enviarLogin(login, senha)
            }
        }
    }

    enviarLogin(login, senha) {
        const loggedUser = { username: login, password: senha }
        this.authenticationService.login(login, senha).subscribe(
            (data) => {
                if (data.success) {
                    this._authService.getToken(loggedUser).subscribe((res) => {
                        this.resetRecaptcha();
                        this.dashboardService.getAllRamos(res.access_token).subscribe((ramoRes) => {
                            // Seta estado do usuário como logado:
                            this.authenticationService.loggedIn.next(true);
                            // Armazena os dados do menu no localStorage
                            this.storageService.set({ storageKey: EStorage.MENU, data: ramoRes.body, expire: moment().add(1, 'hour').toDate() })
                            if (this.returnUrl) {
                                let params = {};

                                let urlSplit = this.returnUrl.split('?');
                                if (urlSplit[1]) {
                                    urlSplit[1].split('&').forEach(x => {
                                        let param = x.split('=');
                                        params[param[0]] = param[1];
                                    })
                                }
                                this.router.navigate([urlSplit[0]], { queryParams: params });
                            } else {
                                this.router.navigateByUrl(`/dashboard`, {
                                    state: { modalPosLogin: true }
                                });
                            }

                        })
                        
                    },
                    err => {
                        if (err.status == 401) {
                            this.message = err.error.errors[0].message || 'Usuário ou senha inválidos.'
                        }
                    })
                }
                    else {
                    this.tentativasLogin++;
                    this.showRecaptcha();
                    this.message = data.message;
                }
            },
            (error) => {
                this.tentativasLogin++;
                this.showRecaptcha();
                if (error.status == HttpStatusEnum.Accepted)
                    this.message = 'Ocorreu um erro inesperado durante esta ação.';
                else
                    this.message = error.error.MSG;
            })

    }

    submitAlterarSenha() {
        if (this.alterarSenhaForm.valid && this.token) {

            let countRegras = 0;
            let obj = <AlterarSenhaLogin>this.alterarSenhaForm.value;

            if (obj.password != obj.confirmPassword) {
                this.notificationService.info('A confirmação de senha não está igual a nova senha!!!');
                return;
            }

            if (obj.password.length < 8 || obj.password.length > 16) {
                this.notificationService.info('A senha deve ter no mínimo 8 e no máximo 16 caracteres!!!');
                return;
            }

            if (this.hasLowerCase(obj.password))
                countRegras++;

            if (this.hasNumber(obj.password))
                countRegras++;

            if (this.hasSpecialCharacter(obj.password))
                countRegras++;

            if (this.hasUpperCase(obj.password))
                countRegras++;

            if (countRegras < 3) {
                this.notificationService.info("A senha deve conter, no mínimo, 3 classes de caracteres: letras. maiúsculas, letras minúsculas, números ecaracteres especiais.");
                return;
            }

            this.usuarioService.gravarSenha(this.alterarSenhaForm.controls.password.value, this.token).subscribe(
                (data) => {
                    this.message = undefined;
                    this.notificationService.success('Senha alterada com sucesso!!!');
                    this.isEsqueciSenha = false;
                    this.isAlterarSenha = false;
                    this.isLogin = true;
                    this.loginForm.controls.login.setValue(this.alterarSenhaForm.controls.login.value);
                },
                (error) => {
                    this.message = error.error.MSG;
                })
        }
    }

    submitEsqueciSenha() {
        if (this.esqueciSenhaForm.valid)
            this.usuarioService.esqueciSenha(this.esqueciSenhaForm.controls.login.value).subscribe((data) => {
                this.notificationService.success(data.MSG);
                this.isEsqueciSenha = false;
                this.isLogin = true;
                this.loginForm.controls.login.setValue(this.esqueciSenhaForm.controls.login.value);
                this.loginForm.controls.password.setValue('');
            },
                (error) => {
                    this.message = error.error.MSG;
                })

    }

    esqueciMinhaSenha(): void {
        this.isEsqueciSenha = true;
        this.isLogin = false;
        this.message = '';
    }

    loginRnsPessoas(): void {
        this.isLoginRnsPessoas = true;
        this.isLoginPadrao = false;
        this.message = '';
        this.loginForm.controls.login.clearValidators();
        this.loginForm.controls.seguradora.setValidators([Validators.required]);
        this.loginForm.controls.subcodigo.setValidators([Validators.required]);
        this.loginForm.controls.usuario1.setValidators([Validators.required]);
        this.loginForm.controls.usuario2.setValidators([Validators.required]);
        this.limparFormLogin();
    }

    loginPadrao(): void {
        this.isLoginRnsPessoas = false;
        this.isLoginPadrao = true;
        this.message = '';
        this.loginForm.controls.login.setValidators([Validators.required]);
        this.loginForm.controls.seguradora.clearValidators();
        this.loginForm.controls.subcodigo.clearValidators();
        this.loginForm.controls.usuario1.clearValidators();
        this.loginForm.controls.usuario2.clearValidators();
        this.limparFormLogin();
    }

    limparFormLogin(): void {
        this.loginForm.controls.login.setValue(undefined);
        this.loginForm.controls.seguradora.setValue(undefined);
        this.loginForm.controls.subcodigo.setValue(undefined);
        this.loginForm.controls.usuario1.setValue(undefined);
        this.loginForm.controls.usuario2.setValue(undefined);
    }

    voltarEsqueciMinhaSenha(): void {
        this.isEsqueciSenha = false;
        this.isLogin = true;
        this.isLoginPadrao = true;
        this.message = '';
    }

    openSobre(): void {
        window.open("https://portaldesolucoes-preprod.cnseg.org.br/sobre-o-portal/")
    }

    voltarAlterarSenha(): void {
        this.isAlterarSenha = false;
        this.isEsqueciSenha = false;
        this.message = '';
    }

    openPortalFinanceiro(): void {
        window.open(environment.urlFinanceiro, '_blank');
    }

    openCentralAtendimento(): void {
        window.open(environment.urlCentralAtendimento, '_blank')
    }

    openDiretivaPrivacidade(): void {
        window.open('#/publico/diretiva-privacidade', '_blank')
    }

    openProtecaoDados(): void {
        window.open(environment.urlProtecaoDados, '_blank')
    }

    hasLowerCase(str): boolean {
        return (/[a-z]/.test(str));
    }

    hasUpperCase(str): boolean {
        return (/[A-Z]/.test(str));
    }

    hasSpecialCharacter(str): boolean {
        return /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(str);
    }

    hasNumber(str): boolean {
        return (/[0-9]/.test(str));
    }

    montarLoginRNSPessoas() {
        return `${this.loginFormRnsPessoas.controls.seguradora.value}_${this.loginFormRnsPessoas.controls.subcodigo.value}_${this.loginFormRnsPessoas.controls.usuario1.value}_${this.loginFormRnsPessoas.controls.usuario2.value}`
    }

    recaptchaValidator() {
        if (this.mostrarRecaptcha) {
            this.reCaptchaV3Service.execute(environment.recaptcha.siteKey, 'login', (token) => {
                this.recaptchaTokenValidation = token;
            }, {
                useGlobalDomain: false
            });
        }
    }

    showRecaptcha(): void {
        let recaptachaSessao;

        if (sessionStorage.getItem('recaptcha')) {
            recaptachaSessao = Number(sessionStorage.getItem('recaptcha'));

            if (recaptachaSessao <= this.totalTentativasLogin)
                this.tentativasLogin = recaptachaSessao + 1;
        }

        sessionStorage.setItem('recaptcha', this.tentativasLogin.toString())
        if (this.tentativasLogin >= this.totalTentativasLogin) {
            this.mostrarRecaptcha = true;
            this.recaptchaValidator()
        }
    }

    resetRecaptcha(): void {
        this.tentativasLogin = 0;
        this.mostrarRecaptcha = false;
        sessionStorage.removeItem('recaptcha');
    }

    get passwordLogin() {
        return this.loginForm.controls.password;
    }

    get passwordLoginRnsPessoas() {
        return this.loginFormRnsPessoas.controls.password;
    }
}