import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import MenuPowerBi from '@assets/json/menuPowerBI.json';
import { IRamos } from '@core/models/ramo.models';
import { environment } from '@env/environment';
import { LocalStorageEnum } from '@modules/consulta/produtos/rnpa-pessoas/enumerators';
import { Observable } from 'rxjs';
import { ApiClass } from './api-class';

@Injectable({
    providedIn: 'root'
})
export class DashboardService extends ApiClass {

    private environmentUrl = environment.apiGatewayDns
    private homeMenuUrl = '/portalservicos/home/menus'


    constructor(protected httpClient: HttpClient) {
        super(httpClient)
    }

    get powerBiMenu() {
        return MenuPowerBi
    }

    getAllRamos(token?:string): Observable<HttpResponse<IRamos[]>> {
        const parsedToken = token || localStorage.getItem(LocalStorageEnum.TokenSensedia)
        const headers = new HttpHeaders({'Authorization': 'Bearer '+parsedToken, 'rnpa_req': 'true'})
        const params = new HttpParams({});
        const url = this.environmentUrl + this.homeMenuUrl
        return this.httpClient.get<IRamos[]>(url, {headers, params, observe: 'response'})

    }

}