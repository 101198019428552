export const enumDictionary = {
  'Status': {
    'EM_ANALISE': 'Em análise',
    'RECUSADA': 'Recusada',
    'ACEITA': 'Aceita',
    'VIGENTE': 'Vigente',
    'CANCELADA': 'Cancelada',
    'PROCESSANDO': 'Processando',
    'PROCESSADO': 'Processado',
    'AGUARDANDO_PROCESSAMENTO': 'Aguardando Processamento',
    'PROCESSADO_ERRO': 'Processado',
    'ERRO': 'Erro',
    'Pendente': 'Pendente',
    'Em Andamento': 'Em andamento',
    'Finalizado': 'Finalizado',
    'Regular': 'Regular',
    'Cancelado': 'Cancelado',
    'Irregular': 'Irregular',
  },
  'TipoCadastro': {
    'API_INDIVIDUAL': 'Cadastro Individual por API',
    'API_LOTE': 'Cadastro em Lote por API',
    'TELA_INDIVIDUAL': 'Cadastro em tela individual',
    'TELA_LOTE': 'Cadastro em lote por tela'
  },
  'Grupo': {
    'VI': 'Vida Individual',
    'VG': 'Vida Grupo'
  },
  'AtivoStatus': {
    'ATIVO': 'Ativo',
    'INATIVO': 'Inativo'
  }, 
  'TipoConsultaAssistencia':{
    'cpfCnpj': 'CPF/CNPJ',
    'placa': 'Placa',
    'chassi': 'Chassi',
    'telefone': 'Telefone Solicitante'
  }
};
