import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-tag-hmg',
  templateUrl: './tag-hmg.component.html',
  styleUrls: ['./tag-hmg.component.scss']
})
export class TagHmgComponent implements OnInit {

  isProduction: boolean = environment.production

  constructor() { }

  ngOnInit(): void {
  }

}
